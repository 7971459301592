*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #474748;
  --white: #ffffff;
  --bg: #F2F2F2;
}

html {
  scroll-behavior: smooth;
}

/*@media (min-width: 1440px) {*/
/*  html {*/
/*    zoom: 1.5;*/
/*  }*/
/*}*/

/*@media (min-width: 2560px) {*/
/*  html {*/
/*    zoom: 1.7;*/
/*  }*/
/*}*/

/*@media (min-width: 3860px) {*/
/*  html {*/
/*    zoom: 2.5;*/
/*  }*/
/*}*/

::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.125rem;
  background: #474748;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #474748;
}

::-webkit-scrollbar-track {
  background: #F2F2F2;
}

body {
  background: var(--bg);
  font-family: 'Inter', serif;
}

.heading {
  font-size: 3.5rem;
  text-align: center;
}

.swiper-images .swiper-slide {
  width: 80%;
  opacity: 0.8;
}

.swiper-images .swiper-slide-active {
  opacity: 1;
}

.swiper-images .swiper-slide img {
  width: 100%;
  aspect-ratio: 1.586;
  border-radius: 1rem;
  object-fit: cover;
}

.swiper-images .swiper-slide-shadow-left,
.swiper-images .swiper-slide-shadow-right {
  display: none;
}

.swiper-images .slider-controller .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .swiper-images .slider-controller .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .swiper-images .slider-controller .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .swiper-images .slider-controller .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .swiper-images .slider-controller .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.swiper-images .slider-controller .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-images .slider-controller .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.swiper-images .slider-controller .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
  padding: 0 10px 2px 10px;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #474748;
}

.swiper-chips .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.burger {
  width: 22px !important;
}

.burger,
.burger::before,
.burger::after {
  background: #474748 !important;
}

.burger::before {
  top: -7px !important;
}

.burger::after {
  top: 7px !important;
}

.burger.burger-close.is-closed::before {
  transform: rotate(-45deg) translate(-4px, 4px) !important;
}

.burger.burger-close.is-closed::after {
  transform: rotate(45deg) translate(-6px, -6px) !important;
}

.burger.burger-arrow.is-closed::before {
  transform: rotate(-45deg) translate(-2px, -2px) !important;
}

.burger.burger-arrow.is-closed::after {
  transform: rotate(45deg) translate(-2px, 2px) !important;
}

.slick-center>div {
  transform: scale(1.08);
  transition: 0.25s;
  padding: 2px 2px !important;
}

.slick-track {
  margin-top: 8px;
  margin-bottom: 8px;
}

.slick-slide>div {
  padding: 8px 12px;
}

/* Arrows */
.slick-prev
{
  left: 0 !important;
}
.slick-prev:before
{
  content: none !important;
}

.slick-next
{
  right: 0 !important;
}
.slick-next:before
{
  content: none !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
  color: #BEBEBE !important;
  outline: none;
  background: white !important;
}

.slick-arrow.slick-next,
.slick-arrow.slick-prev {
  background-color: white;
  color: #BEBEBE;
  height: 36px !important;
  width: 36px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50% !important;
  opacity: 0.9 !important;
  position: absolute !important;
  top: 50% !important;
  z-index: 1000;
}

.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:hover
{
  background-color: white !important;
  opacity: 1 !important;
}


.slick-prev::before,.slick-next::before{
  display: none !important;
}

.arrows{
  height: 20px !important;
  width: 20px !important;
  margin: 1px !important;
}

/* Dots */
.slick-dots
{
  bottom: -15px !important;
}
.slick-dots li
{
  width: 8px !important;
  height: 20px !important;
  margin: 0 4px !important;
}
.slick-dots li button:before
{
  color: #7C7C7C !important;
  font-size: 8px !important;
}
.slick-dots li.slick-active button:before
{
  opacity: 1;
  color: #474748 !important;
  transform: scale(1.2);
}
